import axios from 'axios';

// const BACKEND_URL = 'http://localhost:4001/api'
// const BACKEND_URL = 'https://gaming-portal-backend.vercel.app/api'
const BACKEND_URL = '/api';
// const BACKEND_URL = `http://5.189.169.122:4080`

export const fetchDataFromBackend=async()=>{
    // console.log('here')
    try{
        const response = await axios.get(`${BACKEND_URL}/games`);
        // console.log(response);
        return response;
    }
    catch(err){
        console.log(err)
        return err;
    }
}


export const testApi =async()=>{
    const response = await axios.post(`https://api.digiflazz.com/v1/cek-saldo`, {
        "cmd": "deposit",
        "username": "vudusaopXwxo",
        "sign": "d28ef7a17a10b3a555a637efdc2a3019"
      });
}

