import React,{useState,useEffect} from 'react'
import SubLayout from '../Components/SubLayout'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useSelector,useDispatch } from 'react-redux';
import { fetchDataFromBackend, testApi } from '../Services/api';
import categorySlice from '../Slices/categorySlice';
import classes from './Home.module.css'
import Sidebar from '../Components/Sidebar';
import {useNavigate} from 'react-router-dom';
import Layout from '../Components/Layout';
import { setOpen } from '../Slices/modalSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'
import 'swiper/css/navigation';

import { Autoplay, Pagination,Navigation } from 'swiper/modules';
import Lottie from 'lottie-react'
import Loader from '../Animations/loading.json'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';


const Home = () => {

  const navigate = useNavigate();
    const dispatch = useDispatch();
//   const {open} = useSelector((state)=>state.sideBarSlice)
  const {category} = useSelector((state)=>state.categorySlice);

  const [loading,setLoading] = useState(false);

  const [arr,setArr] = useState([])
  const [newData,setNewData] = useState([]);
  // const [loading,setLoading] = useState(true);

  console.log(arr,'============arrr')

  console.log(newData,'======================nd')
  console.log(category)

    const fetchData =async()=>{
      setLoading(true);
      const data = await fetchDataFromBackend();
      // console.log(data.data);
      const Games = data.data;

      const arr_data = Games?.filter((item)=>item.status == "new")
      setNewData(arr_data);

      let arr;
      if(category=="All Games"){
        setArr(Games);
        setLoading(false)
      }
      else if(category == "New"){
         arr = Games?.filter((item)=>item.status == "new")
        setArr(arr);
        setLoading(false)
      }
      else if(category == "Quiz"){
         arr = Games?.filter((item)=>item.category == "Quiz")
        setArr(arr);
        setLoading(false)
      }
      else if(category == "Games"){
         arr = Games?.filter((item)=>item.category == "Games")
        setArr(arr);
        setLoading(false)
      }
      else if(category == "Fantasy Sports"){
         arr = Games?.filter((item)=>item.category == "Fantasy Portal")
        setArr(arr);
        setLoading(false)
      }
      else if(category == "Videos"){
         arr = Games?.filter((item)=>item.category == "Videos")
        setArr(arr);
        setLoading(false)
      }
      else if(category == "AI"){
        arr = Games?.filter((item)=>item.category == "AI")
       setArr(arr);
       setLoading(false)
     }
     
    }

    useEffect(()=>{
      fetchData();
    },[category])


    const test=async()=>{
      const res = await testApi();
      console.log(res)
    }



    // useEffect(()=>{
    //   test();
    // },[])



    // console.log(handlePlay)
    const handlePlay=(id)=>{
      console.log(id)
      dispatch(setOpen(true))
      navigate(`/game/${id}`)
    }

    



  return (
    <>
  
    <Layout>
    <Navbar/>
    <Sidebar/>
    <SubLayout>
    <div className={classes.container}>



    {category=="All Games" &&
    <>
<div className={classes.title_div}><img src="/assets/new.png" alt="" className={classes.img} /><h1 className={classes.title}>New</h1></div>
    <Swiper
        slidesPerView={2}
        spaceBetween={15}
        
        pagination={{
          clickable: false,
        }}
        pagination={false}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        // navigation={true}
        
        breakpoints={{
          // when window width is >= 1024px
          1024: {
            slidesPerView: 4,
            spaceBetween:30
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 3,
            spaceBetween:20
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2,
            spaceBetween:15
          },
          430: {
            slidesPerView: 2,
            spaceBetween:15
          },
         
        }}
        modules={[Pagination,Autoplay,Navigation]}
        className="mySwiper"
        className={classes.games}
      >
      {
        newData?.map((item,idx)=>(
          <SwiperSlide className={classes.card_container}>
          {/* <div className={classes.new}><img src="/assets/new.png" alt="" /></div> */}
          <img src={item.img_url} alt='' className={classes.game_img} />
          <div className={classes.detail}>
              <p class={classes.card_title}>{item.game_name.slice(0,18)}</p>
            <button class={classes.button} onClick={()=>handlePlay(item.id)}> <PlayArrowIcon/>View</button>
          </div>
          </SwiperSlide>
       
        ))
      }
        
      </Swiper>
    <br /><br /><br /><br />
    </>

}


{
  loading ? 
  <div className={classes.animation_div}>
  <Lottie
    animationData={Loader}
    className={classes.animation}
  />
  </div>
  :
  
<>
    <div className={classes.title_div}><img src={`${category == 'New' ? `/assets/new.png`: category == 'All Games' ? `/assets/all.png`:category == 'Fantasy Sports' ? `/assets/fantasy.png`: category == 'Games' ? `/assets/games.png`:category == 'Quiz' ? `/assets/quiz.png`: category == 'Videos' ? `/assets/video.png` : category == 'AI' ? `/assets/ai.png`: ""}`} alt="" className={classes.img} /><h1 className={classes.title}>{category}</h1></div>
    
    <div className={classes.games}>
    {
        arr?.map((item,idx)=>(
            <>
  {/* <div class={classes.card__border}></div> */}
  <div className={classes.card_container}>
 {
  item.status == 'new' &&  <div className={classes.new}><img src="/assets/new.png" alt="" /></div>
 }
   <img src={item.img_url} alt='' className={classes.game_img} />
  <div className={classes.detail}>
    <p class={classes.card_title}>{item.game_name.slice(0,18)}</p>

  <button class={classes.button} onClick={()=>handlePlay(item.id)}> <PlayArrowIcon/> {" "} View</button>
  </div>
  </div>
  
</>
        ))
    }

    
   
    
    </div>
</>
}



    </div>
    </SubLayout>
    <Footer/>
    </Layout>
  
    </>
  )
}

export default Home
